import { configDetailList, getProjectDepartmentList } from 'api/common';
export const selectList = {
  data() {
    return {
      projectList: [], //所属项目部
      statusList: [], //状态下拉列表
      timeList: [], //时态下拉列表
      acceptList: [], //是否可接受下拉列表
      dangerFactorList: [] //危险危害因素分类
    };
  },
  methods: {
    configDetailList(boolean) {
      //true自定义不加全选，反之
      //获取下拉配置项
      configDetailList({ module: 1 }).then(res => {
        if (res.code === 200) {
          const { one_type, one_state, one_tense, one_accept } = res.data;
          if (boolean) {
            if (one_type.length) {
              one_type.unshift({ name: '全选', id: '' });
            }
            if (one_state.length) {
              one_state.unshift({ name: '全选', id: '' });
            }
            if (one_tense.length) {
              one_tense.unshift({ name: '全选', id: '' });
            }
            if (one_accept.length) {
              one_accept.unshift({ name: '全选', id: '' });
            }
          }
          this.dangerFactorList = one_type;
          this.statusList = one_state;
          this.timeList = one_tense;
          this.acceptList = one_accept;
        }
      });
    },
    getProjectDepartmentList(boolean) {
      //这个单独接口获取(所属项目部)
      getProjectDepartmentList().then(res => {
        if (res.code === 200) {
          let { data } = res;
          if (boolean) {
            //true自定义不加全选，反之
            data.unshift({ name: '全选', id: '' });
          }
          this.projectList = data;
        }
      });
    }
  }
};
