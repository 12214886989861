<template>
  <div class="echart-display">
    <!-- 搜素部分 -->
    <div class="search-list">
      <el-form ref="form" inline :model="queryForm">
        <el-form-item label="所属项目部">
          <el-select
            v-model="queryForm.ids"
            placeholder="请选择项目部"
            multiple
            collapse-tags
            @change="changeProjectList"
          >
            <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="其它筛选条件">
          <el-select
            v-model="queryForm.typeIds"
            placeholder="请选择危险危害因素分类"
            collapse-tags
            multiple
            @change="changeFactorList"
          >
            <el-option v-for="item in dangerFactorList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="queryForm.stateIds"
            placeholder="请选择状态"
            multiple
            collapse-tags
            @change="changeStatusList"
          >
            <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="queryForm.tenseIds"
            placeholder="请选择时态"
            multiple
            collapse-tags
            @change="changeTenseList"
          >
            <el-option v-for="item in timeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="queryForm.acceptIds"
            placeholder="请选择是否可接受"
            multiple
            collapse-tags
            @change="changeAcceptList"
          >
            <el-option v-for="item in acceptList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- 图表 -->
    <div>
      <echarts-line ref="echartRef" :x-data="xData" :data-list="dataList"></echarts-line>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { selectList } from '../selectList.js'; //这里才用混入，下拉匹配项的数据都有了，父组件和子组件弹窗都要用到
import { getPhotoData } from 'api/general-user/prevention-control/hazard-identity';
import echartsLine from 'components/EchartsLine';
export default {
  mixins: [selectList],
  components: {
    echartsLine
  },
  data() {
    return {
      queryForm: {
        ids: [],
        typeIds: [],
        acceptIds: [],
        tenseIds: [],
        stateIds: []
      },
      xData: [], //图表横坐标
      dataList: [], //图表数据
      isCheckedAllProjectFlag: false, //所属项目部(是否全选)
      isCheckedAllStateFlag: false, //状态(是否全选)
      isCheckedAllDangerFactorFlag: false, //危险危害因素(是否全选)
      isCheckedAllTenseFlag: false, //时态(是否全选)
      isCheckedAllAcceptFlag: false //是否可接受(是否全选)
    };
  },
  methods: {
    changeProjectList(id) {
      //选择属项目部
      if (!this.isCheckedAllProjectFlag) {
        if (id.includes('')) {
          //只要选择了全选，下面的所有都选择
          let arr = [];
          this.projectList.map(item => {
            arr.push(item.id);
          });
          this.queryForm.ids = arr;
          this.isCheckedAllProjectFlag = true;
        } else {
          this.queryForm.ids = id;
        }
      } else {
        this.isCheckedAllProjectFlag = false;
        this.queryForm.ids = id.filter(item => item !== '');
      }
      this.getPhotoData();
    },
    changeFactorList(id) {
      //选择危险危害因素
      this.queryForm.stateIds = [];
      this.queryForm.acceptIds = [];
      this.queryForm.tenseIds = [];
      if (!this.isCheckedAllDangerFactorFlag) {
        if (id.includes('')) {
          //只要选择了全选，下面的所有都选择
          let arr = [];
          this.dangerFactorList.map(item => {
            arr.push(item.id);
          });
          this.queryForm.typeIds = arr;
          this.isCheckedAllDangerFactorFlag = true;
        } else {
          this.queryForm.typeIds = id;
        }
      } else {
        this.isCheckedAllDangerFactorFlag = false;
        this.queryForm.typeIds = id.filter(item => item !== '');
      }
      this.getPhotoData();
    },
    changeStatusList(id) {
      //选择下拉状态
      this.queryForm.typeIds = [];
      this.queryForm.acceptIds = [];
      this.queryForm.tenseIds = [];
      //选择属项目部
      if (!this.isCheckedAllStateFlag) {
        if (id.includes('')) {
          //只要选择了全选，下面的所有都选择
          let arr = [];
          this.statusList.map(item => {
            arr.push(item.id);
          });
          this.queryForm.stateIds = arr;
          this.isCheckedAllStateFlag = true;
        } else {
          this.queryForm.stateIds = id;
        }
      } else {
        this.isCheckedAllStateFlag = false;
        this.queryForm.stateIds = id.filter(item => item !== '');
      }
      this.getPhotoData();
    },
    changeTenseList(id) {
      //时态选择
      this.queryForm.typeIds = [];
      this.queryForm.acceptIds = [];
      this.queryForm.stateIds = [];
      if (!this.isCheckedAllTenseFlag) {
        if (id.includes('')) {
          //只要选择了全选，下面的所有都选择
          let arr = [];
          this.timeList.map(item => {
            arr.push(item.id);
          });
          this.queryForm.tenseIds = arr;
          this.isCheckedAllTenseFlag = true;
        } else {
          this.queryForm.tenseIds = id;
        }
      } else {
        this.isCheckedAllTenseFlag = false;
        this.queryForm.tenseIds = id.filter(item => item !== '');
      }
      this.getPhotoData();
    },
    changeAcceptList(id) {
      //是否可接受
      this.queryForm.typeIds = [];
      this.queryForm.tenseIds = [];
      this.queryForm.stateIds = [];
      if (!this.isCheckedAllAcceptFlag) {
        if (id.includes('')) {
          //只要选择了全选，下面的所有都选择
          let arr = [];
          this.acceptList.map(item => {
            arr.push(item.id);
          });
          this.queryForm.acceptIds = arr;
          this.isCheckedAllAcceptFlag = true;
        } else {
          this.queryForm.acceptIds = id;
        }
      } else {
        this.isCheckedAllAcceptFlag = false;
        this.queryForm.acceptIds = id.filter(item => item !== '');
      }
      this.getPhotoData();
    },
    getPhotoData() {
      //项目部危险源数量总数（默认）简单柱状图
      let params = cloneDeep(this.queryForm);
      if (params.ids.includes('')) {
        //判断数组中是否有空字符串
        params.ids = []; //传[]或者所有id集合或者不传字段三种都可以（只有项目部才有，其他都传id集合）
      }
      if (params.typeIds.includes('')) {
        params.typeIds = params.typeIds.filter(item => item !== '');
      }
      if (params.stateIds.includes('')) {
        params.stateIds = params.stateIds.filter(item => item !== '');
      }
      if (params.tenseIds.includes('')) {
        params.tenseIds = params.tenseIds.filter(item => item !== '');
      }
      if (params.acceptIds.includes('')) {
        params.acceptIds = params.acceptIds.filter(item => item !== '');
      }
      getPhotoData(params).then(res => {
        if (res.code === 200) {
          const { dangerSourcePhotoResults, name, allNum } = res.data;
          this.xData = name; //横坐标的值
          if (res.code === 200) {
            if (dangerSourcePhotoResults.length) {
              //只要选择了其他下拉选择，dangerSourcePhotoResults字段就有值
              dangerSourcePhotoResults.forEach(item => {
                item.type = 'bar';
                item.stack = 'bar';
                item.barWidth = 20;
                item.data = item.allNum;
              });
              this.dataList = dangerSourcePhotoResults;
            } else {
              //只选择了所属项目部
              this.dataList = [
                {
                  data: allNum,
                  type: 'bar',
                  barWidth: 20,
                  backgroundColor: '#1890FF',
                  name: '危险源数量'
                }
              ];
            }
          }
          this.$nextTick(() => {
            this.$refs.echartRef.initEchartsLine(); //调用子组件echarts里面的配置方法
          });
        }
      });
    }
  },
  created() {
    this.getPhotoData();
    this.configDetailList(true);
    this.getProjectDepartmentList(true);
  }
};
</script>

<style scoped lang="less"></style>
