export const validateRules = {
  data() {
    var validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入危险源名称'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateProjectDepartment = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择所属项目部'));
      }
      callback();
    };
    var validateProcess = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入过程（区域）'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateBehavior = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入行为（活动）、设备或环境'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateTypeId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入危险危害因素分类'));
      }
      callback();
    };
    var validatePotentialFactor = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入潜在的危险危害因素'));
      } else if (value.length > 200) {
        return callback(new Error('最大字符为200'));
      }
      callback();
    };
    var validateAccident = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入可能导致的事故'));
      } else if (value.length > 200) {
        return callback(new Error('最大字符为200'));
      }
      callback();
    };
    var validateControlMeasures = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入控制措施'));
      } else if (value.length > 200) {
        return callback(new Error('最大字符为200'));
      }
      callback();
    };
    var validateStateId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择状态'));
      }
      callback();
    };
    var validateTenseId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择时态'));
      }
      callback();
    };
    var validateAcceptId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择是否可接受'));
      }
      callback();
    };
    var validateEvaluationMethod = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入评价方法'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    return {
      rules: {
        name: [{ validator: validateName }],
        projectDepartment: [{ validator: validateProjectDepartment, trigger: 'change' }],
        process: [{ validator: validateProcess }],
        behavior: [{ validator: validateBehavior }],
        typeId: [{ validator: validateTypeId, trigger: 'change' }],
        potentialFactor: [{ validator: validatePotentialFactor }],
        accident: [{ validator: validateAccident }],
        controlMeasures: [{ validator: validateControlMeasures }],
        stateId: [{ validator: validateStateId, trigger: 'change' }],
        tenseId: [{ validator: validateTenseId, trigger: 'change' }],
        acceptId: [{ validator: validateAcceptId, trigger: 'change' }],
        evaluationMethod: [{ validator: validateEvaluationMethod }]
      }
    };
  }
};
