<template>
  <div>
    <!-- 列表搜索 -->
    <div class="search-list">
      <el-form inline>
        <el-form-item label="模糊查询">
          <el-input
            v-model="queryForm.search"
            placeholder="请输入危险源名称/过程（区域）"
            style="width:280px"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属项目部">
          <el-select v-model="queryForm.ids" placeholder="请选择项目部" multiple collapse-tags>
            <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="其它筛选条件">
          <el-select v-model="queryForm.typeIds" placeholder="请选择危险危害因素分类" multiple collapse-tags>
            <el-option v-for="item in dangerFactorList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="queryForm.stateIds" placeholder="请选择状态" multiple collapse-tags>
            <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="queryForm.tenseIds" placeholder="请选择时态" multiple collapse-tags>
            <el-option v-for="item in timeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="queryForm.acceptIds" placeholder="请选择是否可接受" multiple collapse-tags>
            <el-option v-for="item in acceptList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handelSearch">查询</el-button>
          <el-button @click="handelReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 操作按钮 -->
    <operate-button>
      <template #default>
        <el-button
          type="primary"
          icon="el-icon-plus"
          class="add-btn"
          @click="addDangerSource"
          v-if="$check('double-prevention_add')"
          >新增危险源</el-button
        >
        <el-button
          v-if="$check('double-prevention_export')"
          class="export-btn"
          @click="handleExport"
          :disabled="btnDisabeld"
          v-loading.fullscreen="exportLoading"
          element-loading-text="拼命导出中"
          element-loading-background="rgba(0, 0, 0, 0.1)"
          >导出</el-button
        >
      </template>
    </operate-button>
    <!-- 列表 -->
    <div>
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        v-loading="tableLoading"
        ref="multipleTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center" fixed> </el-table-column>
        <el-table-column label="序号" width="55" align="center" fixed type="index"> </el-table-column>
        <el-table-column prop="name" label="危险源名称" align="center" fixed show-overflow-tooltip sortable>
        </el-table-column>
        <el-table-column prop="projectDepartmentName" label="所属项目部" align="center" show-overflow-tooltip sortable>
        </el-table-column>
        <el-table-column prop="process" label="过程（区域）" align="center" show-overflow-tooltip sortable>
        </el-table-column>
        <el-table-column prop="behavior" label="行为（活动）、设备或环境" align="center" show-overflow-tooltip sortable>
        </el-table-column>
        <el-table-column prop="typeName" label="危险危害因素分类" align="center" show-overflow-tooltip sortable>
        </el-table-column>
        <el-table-column prop="stateName" label="状态" align="center" width="100" sortable> </el-table-column>
        <el-table-column prop="tenseName" label="时态" align="center" width="100" sortable> </el-table-column>
        <el-table-column prop="acceptName" label="是否可接受" align="center" width="120" sortable> </el-table-column>
        <el-table-column prop="address" label="操作" align="center" width="120" fixed="right">
          <template slot-scope="{ row }">
            <span class="action-btn" @click="viewDetail(row)">查看详情</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :total="total"
        :current-page.sync="queryForm.pageNo"
        :page-size.sync="queryForm.pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 50]"
        layout="total, sizes, prev, pager, next"
      ></el-pagination>
    </div>
    <!-- dialog -->
    <list-display-dialog
      ref="formRef"
      :dialog-title.sync="dialogTitle"
      :dialog-visible.sync="dialogVisible"
      :is-read.sync="isRead"
      :project-list="projectList"
      :status-list="statusList"
      :time-list="timeList"
      :accept-list="acceptList"
      :danger-factor-list="dangerFactorList"
      @handel-success="handelSuccess"
    ></list-display-dialog>
  </div>
</template>

<script>
import { exportFun } from '@/utils/export.js';
import { selectList } from '../selectList.js'; //这里才用混入，下拉匹配项的数据都有了，父组件和子组件弹窗都要用到
import listDisplayDialog from './listDisplayDialog';
import operateButton from '../../_components/operateButton';
import { getDangerSourcePage, exportDangerSource } from 'api/general-user/prevention-control/hazard-identity';
export default {
  mixins: [selectList],
  components: {
    listDisplayDialog,
    operateButton
  },
  data() {
    return {
      dialogVisible: false,
      dialogTitle: '',
      isRead: false,
      tableLoading: false,
      exportLoading: false,
      queryForm: {
        search: '',
        ids: [],
        typeIds: [],
        stateIds: [],
        tenseIds: [],
        acceptIds: [],
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      total: 0,
      //导出需要的ids
      ids: [],
      btnDisabeld: true
    };
  },
  methods: {
    handleSelectionChange(row) {
      this.ids = [];
      row.forEach(item => {
        this.ids.push(item.id);
      });
      this.btnDisabeld = !Boolean(this.ids.length);
    },
    //导出
    handleExport() {
      this.exportLoading = true;
      exportDangerSource({
        ids: this.ids
      })
        .then(res => {
          if (res.status === 200) {
            this.exportLoading = false;
            exportFun(res);
            this.$message({
              type: 'success',
              message: '导出数据成功',
              onClose: () => {
                this.$refs.multipleTable.clearSelection();
              }
            });
          }
        })
        .catch(() => {
          this.exportLoading = false;
          this.$refs.multipleTable.clearSelection();
        });
    },
    //分页查询
    getDangerSourcePage() {
      this.tableLoading = true;
      getDangerSourcePage(this.queryForm).then(res => {
        if (res.code === 200) {
          this.tableLoading = false;
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    addDangerSource() {
      //新增危险源
      this.dialogVisible = true;
      this.dialogTitle = '新增危险源';
    },
    viewDetail(row) {
      //查看详情
      this.isRead = true;
      this.dialogVisible = true;
      const obj = JSON.parse(JSON.stringify(row)); //这里偷懒用下深拷贝，没设计到太复杂的拷贝
      this.$refs.formRef.initFormDetail(obj); //调用子组件的方法时使表单回显
      this.dialogTitle = '查看详情';
    },
    handelSuccess(boolean) {
      //true->新增，false编辑
      //刷新列表
      if (boolean) {
        this.queryForm.pageNo = 1;
      }
      this.getDangerSourcePage();
    },
    handleSizeChange(size) {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = size;
      this.getDangerSourcePage();
    },
    handleCurrentChange(current) {
      this.queryForm.pageNo = current;
      this.getDangerSourcePage();
    },
    handelReset() {
      //重置
      this.queryForm = {};
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = 10;
      this.$refs.multipleTable.clearSort();
      this.getDangerSourcePage();
    },
    handelSearch() {
      //搜索
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = 10;
      this.getDangerSourcePage();
    }
  },
  created() {
    this.getDangerSourcePage();
    this.configDetailList(false);
    this.getProjectDepartmentList(false);
  }
};
</script>

<style scoped lang="less">
.search-list {
  border-bottom: 1px solid #dcdfe7;
}
</style>
