<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="show"
    width="1165px"
    :close-on-click-modal="false"
    @close="handelClose"
  >
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" v-loading="dialogLoading">
      <el-row>
        <el-col :span="10">
          <el-form-item label="危险源名称" prop="name" label-width="150px" :required="!isRead">
            <el-input
              v-model="ruleForm.name"
              placeholder="请输入"
              style="width:364px"
              clearable
              :disabled="isRead"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11" :offset="2">
          <el-form-item label="所属项目部" prop="projectDepartment" :required="!isRead">
            <el-select v-model="ruleForm.projectDepartment" placeholder="请选择" style="width:364px" :disabled="isRead">
              <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="过程（区域）" label-width="150px" prop="process" :required="!isRead">
            <el-input
              v-model="ruleForm.process"
              placeholder="请输入"
              style="width:364px"
              clearable
              :disabled="isRead"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11" :offset="2">
          <el-form-item label="行为（活动）、设备或环境" prop="behavior" :required="!isRead">
            <el-input
              v-model="ruleForm.behavior"
              style="width:364px"
              placeholder="请输入"
              clearable
              :disabled="isRead"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="危险危害因素分类" label-width="150px" prop="typeId" :required="!isRead">
            <el-select
              v-model="ruleForm.typeId"
              placeholder="请选择"
              style="width:364px"
              :disabled="isRead"
              @change="changeDangerFactorList"
            >
              <el-option
                v-for="item in dangerFactorList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="潜在的危险危害因素" label-width="150px" prop="potentialFactor" :required="!isRead">
            <el-input
              v-model="ruleForm.potentialFactor"
              type="textarea"
              placeholder="请输入"
              :maxlength="200"
              :disabled="isRead"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="可能导致的事故" label-width="150px" prop="accident" :required="!isRead">
            <el-input
              v-model="ruleForm.accident"
              type="textarea"
              placeholder="请输入"
              :maxlength="200"
              :disabled="isRead"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="控制措施" label-width="150px" prop="controlMeasures" :required="!isRead">
            <el-input
              v-model="ruleForm.controlMeasures"
              type="textarea"
              placeholder="请输入"
              :maxlength="200"
              :disabled="isRead"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="状态" label-width="150px" prop="stateId" :required="!isRead">
            <el-select
              v-model="ruleForm.stateId"
              placeholder="请选择"
              style="width:364px"
              :disabled="isRead"
              @change="changeStateList"
            >
              <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="11" :offset="2">
          <el-form-item label="时态" prop="tenseId" :required="!isRead">
            <el-select
              v-model="ruleForm.tenseId"
              placeholder="请选择"
              style="width:364px"
              :disabled="isRead"
              @change="changeTenseList"
            >
              <el-option v-for="item in timeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="是否可接受" label-width="150px" prop="acceptId" :required="!isRead">
            <el-select
              v-model="ruleForm.acceptId"
              placeholder="请选择"
              style="width:364px"
              :disabled="isRead"
              @change="changeAcceptList"
            >
              <el-option v-for="item in acceptList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="11" :offset="2">
          <el-form-item label="评价方法" prop="evaluationMethod" :required="!isRead">
            <el-input
              v-model="ruleForm.evaluationMethod"
              style="width:364px"
              placeholder="请输入"
              clearable
              :disabled="isRead"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="备注" label-width="150px">
            <el-input
              v-model="ruleForm.remark"
              type="textarea"
              placeholder="请输入"
              :maxlength="200"
              :disabled="isRead"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" v-if="$check('double-prevention_edit')">
      <span v-if="!isRead">
        <el-button @click="resetForm">取消</el-button>
        <el-button @click="submitForm" type="primary">确定</el-button>
      </span>
      <span v-else>
        <el-button type="danger" @click="handelDelete">删除</el-button>
        <el-button type="primary" @click="handelUpdate">修改</el-button>
      </span>
    </div>
  </el-dialog>
</template>

<script>
import { validateRules } from './validateRules.js';
import {
  addDangerSource,
  deleteDangerSource,
  editDangerSource
} from 'api/general-user/prevention-control/hazard-identity';
export default {
  name: 'listDisplayDialog',
  mixins: [validateRules],
  props: {
    dialogTitle: {
      //标题
      type: String,
      required: true,
      default: ''
    },
    dialogVisible: {
      //显隐
      type: Boolean,
      required: true,
      default: false
    },
    isRead: {
      //是否可读(查看详情为true)
      type: Boolean,
      required: true,
      default: false
    },
    //这些都是父组件传下来的下拉数据（这里没调取接口，感觉太臃肿了，采取的是传值）
    projectList: {
      //所属项目部
      type: Array,
      required: true,
      default: () => {
        [];
      }
    },
    statusList: {
      //状态下拉列表
      type: Array,
      required: true,
      default: () => {
        [];
      }
    },
    timeList: {
      //时态下拉列表
      type: Array,
      required: true,
      default: () => {
        [];
      }
    },
    acceptList: {
      //是否可接受下拉列表
      type: Array,
      required: true,
      default: () => {
        [];
      }
    },
    dangerFactorList: {
      //危险危害因素分类
      type: Array,
      required: true,
      default: () => {
        [];
      }
    }
  },
  data() {
    return {
      dialogLoading: false,
      ruleForm: {},
      show: this.dialogVisible
    };
  },
  methods: {
    initFormDetail(row) {
      this.ruleForm = row; //这里对象中的id有值
    },
    changeDangerFactorList(id) {
      let obj = {};
      obj = this.dangerFactorList.find(item => {
        return item.id === id; //筛选出匹配数据
      });
      this.ruleForm.typeName = obj.name;
    },
    changeAcceptList(id) {
      let obj = {};
      obj = this.acceptList.find(item => {
        return item.id === id; //筛选出匹配数据
      });
      this.ruleForm.acceptName = obj.name;
    },
    changeTenseList(id) {
      let obj = {};
      obj = this.timeList.find(item => {
        return item.id === id; //筛选出匹配数据
      });
      this.ruleForm.tenseName = obj.name;
    },
    changeStateList(id) {
      let obj = {};
      obj = this.statusList.find(item => {
        return item.id === id; //筛选出匹配数据
      });
      this.ruleForm.stateName = obj.name;
    },
    submitForm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.ruleForm.id) {
            //id有值为编辑，否则为新增
            this.editDangerSource();
          } else {
            this.addDangerSource();
          }
        }
      });
    },
    //新增接口
    addDangerSource() {
      addDangerSource(this.ruleForm).then(res => {
        if (res.code === 200) {
          this.$message.success('新增成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handel-success', true);
        }
      });
    },
    //编辑接口
    editDangerSource() {
      editDangerSource(this.ruleForm).then(res => {
        if (res.code === 200) {
          this.$message.success('编辑成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handel-success', false);
        }
      });
    },
    //删除接口
    deleteDangerSource() {
      deleteDangerSource({ id: this.ruleForm.id }).then(res => {
        if (res.code === 200) {
          this.$message.success('删除成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handel-success', false);
        }
      });
    },
    resetForm() {
      this.dialogCloseCommomFunc();
    },
    handelClose() {
      this.dialogCloseCommomFunc();
    },
    dialogCloseCommomFunc() {
      this.$emit('update:dialogVisible', false);
      this.$emit('update:isRead', false);
      this.ruleForm = {};
      this.$refs.ruleForm.resetFields();
    },
    handelUpdate() {
      this.dialogLoading = true;
      this.$emit('update:dialogTitle', '修改危险源');
      this.$emit('update:isRead', false);
      setTimeout(() => {
        this.dialogLoading = false;
      }, 500);
    },
    handelDelete() {
      //删除
      this.$confirm('请确认是否删除该危险源?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.deleteDangerSource();
      });
    }
  },
  watch: {
    dialogVisible(val) {
      this.show = val;
    }
  }
};
</script>
