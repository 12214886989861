import request from '@/utils/request.js';
/**
 *新增危险源
 * @returns {Promise}
 */
export function addDangerSource(data) {
  return request({
    url: '/traffic-construction/addDangerSource',
    method: 'post',
    data
  });
}

/**
 *获取危险源辨识的分页数据
 * @returns {Promise}
 */
export function getDangerSourcePage(data) {
  return request({
    url: '/traffic-construction/getDangerSourcePage',
    method: 'post',
    data
  });
}

/**
 *删除危险源
 * @returns {Promise}
 */
export function deleteDangerSource(data) {
  return request({
    url: '/traffic-construction/deleteDangerSource',
    method: 'post',
    data
  });
}

/**
 *编辑危险源
 * @returns {Promise}
 */
export function editDangerSource(data) {
  return request({
    url: '/traffic-construction/editDangerSource',
    method: 'post',
    data
  });
}

/**
 *获取危险源的图表数据
 * @returns {Promise}
 */
export function getPhotoData(data) {
  return request({
    url: '/traffic-construction/getPhotoData',
    method: 'post',
    data
  });
}

/**
 *导出危险源
 * @returns {Promise}
 */
export function exportDangerSource(data) {
  return request({
    url: '/traffic-construction/exportDangerSource',
    method: 'post',
    data,
    responseType: 'blob'
  });
}
